import * as React from 'react'
import { Translation } from '../../types/main'
import { translations } from '../../translations'
import IndexContainer from '../../containers'
import Seo from '../../components/seo'

const t: Translation = {
  language: 'se',
  translations: translations.se
}

const IndexPage = () => (
  <>
    <Seo title="Flowby - Cut your next line" lang="sv" />
    <IndexContainer t={t} />
  </>
)

export default IndexPage
